@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

.font-family-Rubik {
    font-family: 'Rubik', sans-serif;
}

.font-family-Space-Grotesk {
    font-family: 'Space Grotesk', sans-serif;
}

.active .MiiiM-sidebar-item {
    @apply border-l-4 border-blue-500 bg-blue-400;
}

@media (min-width: 768px) {
    .nav-button:before{
        content: '';
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 0;
        background: white;
        border-radius: 12px;
        transition: all 0.4s ease;
        left: 50%;
        transform: translateX(-50%);
    }
}

.nav-button:hover:before{
    width: 100%;
}

.contact-us-btn, .application-item {
    transition: all 0.4s ease;
}

.contact-us-btn:hover, .application-item:hover {
    transform: scale(1.05);
}

p {
    white-space: pre-line;
}

.shadow-green_100 {
    box-shadow: -5px 8px #0188EB30;
}

.shadow-green_5 {
    box-shadow: -9px 13px 10px -5px #0188EB1D;
}

.shadow-blue-900-center {
    box-shadow: 0px 0px 40px 0px #0C1854;
}

.bg-size-120 {
    background-size: 120%;
}

@keyframes expandWidth {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
}
  
.width-animation {
    width: 80%;
    animation: expandWidth 1s ease-in-out;
}

.editable-content {
    cursor: url('./assets/images/pencile.png'), auto;
    background-color: #ffa50055;
}

/********************** Popup **********************/

.my-popup-content {
    background: transparent;
    padding: 0;
    border: none;
    width: max-content;
}

.popup-body {
    @apply w-[85vw] md:w-[60vw];
}
  







/********************** START : Image DropDown **********************/

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-top: 30px;
}

.dropdown {
    width: 100%;
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease;
    border-radius: 4px;
}

.dropdown-toggle:hover {
    background-color: #f8f8f8;
}

.dropdown-option-image {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 50%;
}

.dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
}

.dropdown-caret {
    display: inline-block;
    margin-left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #999 transparent transparent transparent;
}

.dropdown-menu-container {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.dropdown-menu-container input {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 12px 15px;
}

.dropdown-menu {
    padding: 12px 0;
    background-color: #fff;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown-menu li {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-menu li:hover {
    background-color: #d4d4d4;
}

.dropdown-menu li .dropdown-option-image {
    margin-right: 12px;
}

.dropdown-menu li .dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
}

.dropdown.open .dropdown-menu {
    transform: translateY(0);
}

.dropdown.open .dropdown-toggle {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
}

.dropdown.open .dropdown-menu {
    border-radius: 0 0 4px 4px;
    border-top: none;
}

/********************** END : Image DropDown **********************/